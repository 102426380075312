@use '_colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;800&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    max-height: 100%;
    border: none;
    vertical-align: middle;
}

a {
    color: inherit;
}

body {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    background-color: colors.$grey;
}

h1, h2, h3, h4, h4, h5 {
    font-weight: 800;
}

button,
input,
textarea,
select,
option {
    font-family: 'Noto Sans', sans-serif;
    outline: none;
    border: none;
    appearance: none;
}