@use '../../styles/colors';

.settings {

  max-width: 720px;
  margin: 80px auto;

  p {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  
  fieldset {
    padding: 15px;
    border: 1px solid colors.$darkGrey;

    legend {
      text-transform: capitalize;
      font-size: 14px;
    }

    div:not(:last-child) {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  label {
    display: block;
    text-transform: capitalize;
    font-size: 14px;
    margin-bottom: 5px;
  }

  input {
    padding: 10px;
    line-height: 1;
    border: 1px solid colors.$darkGrey;
    border-radius: 5px;
    width: 100%;
    outline: none;

    &:active, &:focus {
      border-color: colors.$black;
    }

    &[type=submit] {
      border: none;
      background-color: colors.$black;
      color: colors.$white;
      font-weight: 800;
      border-radius: 0;
      font-size: 20px;
      padding: 15px;
      cursor: pointer;
    }
  }
  
  .multiline {
    display: flex;
    gap: 15px;

    p {
      text-transform: capitalize;
      margin-bottom: 0;
      font-size: 14px;
    }
  }

}